// src/App.jsx
import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import './config/firebase';

// Lazy load components for better performance
const Login = React.lazy(() => import('./components/Login'));
const FileManager = React.lazy(() => import('./Pages/FileManager'));
const FileManagerDashboard = React.lazy(() => import('./Pages/FileManagerDashboard'));
const ProtectedRoute = React.lazy(() => import('./components/ProtectedRoute'));
const NotFound = React.lazy(() => import('./Pages/NotFound'));
const DesktopAuth = React.lazy(() => import('./components/DesktopLogin'));

// Loading component
const LoadingSpinner = () => (
  <div className="min-h-screen flex items-center justify-center">
    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600" />
  </div>
);


const App = () => {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Suspense fallback={<LoadingSpinner />}>
          <Routes>
            <Route path="/desktoplogin" element={<DesktopAuth />} />
            <Route path="/404" element={<NotFound />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <FileManagerDashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manage"
              element={
                <ProtectedRoute>
                  <FileManager />
                </ProtectedRoute>
              }
            />
            <Route path="/" element={<Navigate to="/login" replace />} />
            {/* Redirect any unknown routes to 404 */}
            <Route path="*" element={<Navigate to="/404" replace />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </AuthProvider>
  );
};

export default App;